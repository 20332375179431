import React, { useContext } from "react"
import * as styles from "./frontStory.module.css"
import { navigate } from "gatsby-link"
import { topics, storyCategory } from "utils/data.js"
import cookie from "react-cookies"
import { getThumb } from "utils"
import modalContext from "context/modalContext"
import { textEdit } from "utils"
import { permission } from "utils"
import FreeBadge from "components/tools/freeBadge"
import NewBadge from "components/tools/newBadge"
const FrontStory = props => {
  const { main, sub } = storyCategory

  const access_token = cookie.load("access_token", { path: "/" })
  const rank = access_token ? JSON.parse(access_token).rank : 0
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  console.log(1818, props)
  return (
    <div
      className={styles.con}
      onClick={() => {
        navigate(`/story/detail/?number=${props.story_no}`)
      }}
    >
      <div className={styles.firstCon}>
        <div>
          <div>
            {props.free ? (
              <FreeBadge free={props.free} />
            ) : (
              <NewBadge registerTime={props.registerTime} />
            )}
          </div>
          <img src={getThumb(props.thumb_title_uuid)} />
        </div>
        <div>
          <div>{props.title}</div>
          <div>{textEdit(props.introText.substr(0, 100))}</div>
        </div>
      </div>

      <div className={styles.secondCon}>
        <div>
          <div>
            <div>{main.get(props.cateMain)}</div>

            <div>{sub.get(props.cateSub)}</div>
          </div>

          <div>
            {props.StoryTopic?.map(post => `#${topics.get(post).contents}  `)}
          </div>
        </div>

        <div>
          <img
            src={getThumb(props.thumb_suggest_uuid)}
            onClick={() => {
              navigate(`/story/detail/?number=${props.story_no}`)
            }}
          />
          <div>
            <div>이런 선생님께 추천합니다.</div>
            <div>{textEdit(props.suggestText)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FrontStory
