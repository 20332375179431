// extracted by mini-css-extract-plugin
export var con = "front-module--con--3ewaB";
export var slideCon = "front-module--slideCon--3toBS";
export var videoCon = "front-module--videoCon--3TQ5f";
export var video = "front-module--video--2nW7h";
export var detail = "front-module--detail--2FmqE";
export var imgVideo = "front-module--imgVideo--wvpif";
export var imgCard = "front-module--imgCard--1AhM7";
export var nothingStory = "front-module--nothingStory--vHlj1";
export var text = "front-module--text--CUDB-";
export var left = "front-module--left--3Du0Z";
export var right = "front-module--right--2d3TV";
export var popCon = "front-module--popCon--aa_mX";