import React, { useState, useEffect, useContext, useCallback } from "react"
import Layout from "components/common/layout.js"
import * as styles from "./front.module.css"
import Slide from "components/tools/banner/slide"
import { getBannerAsync } from "store/modules/bannerReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import InputTitle from "components/tools/InputTitle.js"
import Slider from "components/tools/slide/Slide.js"
import FrontStory from "../components/story/frontStory"
import DisplayB from "components/tools/banner/displayB"
import {
  getTodayStoryAsync,
  getVideoStoryAsync,
  getSuggestAsync,
  getTodayCardAsync,
  getUserStoryAsync,
  getPickStoryAsync,
  getEdiCurationAsync,
  getEduCurationAsync,
} from "store/modules/frontReducer.js"
import { navigate } from "gatsby-link"
import Story from "components/story/story"
import { getThumb, getJson, permission } from "utils"
import { rank } from "utils/data.js"
import peachIcons from "images/peachIcons.png"
import Lesson from "components/post/lesson.js"
import fullView from "images/smallSliceIcon.png"
import modalContext from "context/modalContext"
import { MINE } from "utils/data"
const Front = () => {
  const dispatch = useDispatch()

  //headerMenu ( full - 정회원, associate - 준회원, none - 비회원)
  const [memberType, setMemberType] = useState(null)

  const getBanner = useCallback(
    position => dispatch(getBannerAsync(position)),
    [dispatch]
  )

  const getTodayStory = useCallback(
    () => dispatch(getTodayStoryAsync()),
    [dispatch]
  )
  const getTodayCard = useCallback(
    () => dispatch(getTodayCardAsync()),
    [dispatch]
  )
  const getUserStory = useCallback(
    memberType => dispatch(getUserStoryAsync(memberType)),
    [dispatch]
  )
  const getPickStory = useCallback(
    () => dispatch(getPickStoryAsync()),
    [dispatch]
  )
  const getEdiCuration = useCallback(
    () => dispatch(getEdiCurationAsync()),
    [dispatch]
  )
  const getEduCuration = useCallback(
    () => dispatch(getEduCurationAsync()),
    [dispatch]
  )
  const getVideo = useCallback(() => dispatch(getVideoStoryAsync()), [dispatch])
  const getSuggest = useCallback(() => dispatch(getSuggestAsync()), [dispatch])

  const {
    todayStory,
    todayCard,
    forUserStory,
    likeStory,
    editorCuration: ediCuration,
    eduCuration,
    videoStory: Video,
    suggest: Suggest,
  } = useSelector(({ frontReducer }) => frontReducer, shallowEqual)

  const access_token = getJson("access_token")
  const profile = getJson("profile")

  const getSlideWidth = array => ({
    width: array.length > 3 ? "1135px" : `${array.length * 270}px`,
  })
  usePopup()

  useEffect(() => {
    ;(async () => {
      getBanner("front")
      getTodayStory()
      getTodayCard()
      getEdiCuration()
      getEduCuration()
      getVideo()
      getSuggest()

      // const access_token = cookie.load("access_token", { path: "/" })
      // const rank = access_token ? JSON.parse(access_token).rank : 0
      const userRank = access_token?.rank ?? 0
      setMemberType(userRank)

      if (userRank == rank.REGULAR) {
        getUserStory("forUserStory")
        getPickStory()
      } else {
        getUserStory("forUnknownUserStory")
      }
    })()
  }, [memberType])

  return (
    <Layout>
      <Slide />
      <div className={styles.con}>
        <div>
          <InputTitle
            name={"오늘의 스토리"}
            titleSize={22}
            style={{
              display: "flex",
              margin: 0,
              marginBottom: 30,
              marginRight: "auto",
            }}
          />

          <div className={styles.slideCon}>
            {todayStory.length > 0 ? (
              <Slider name={"oneSizeSilde"}>
                {todayStory?.map(post => (
                  <FrontStory
                    registerTime={post.publishTime}
                    key={post.story_no}
                    story_no={post.story_no}
                    title={post.title}
                    introText={post.introText}
                    suggestText={post.suggestText}
                    thumb_title_uuid={post.thumb_title_uuid}
                    thumb_suggest_uuid={post.thumb_suggest_uuid}
                    cateMain={post.cateMain}
                    cateSub={post.cateSub}
                    StoryTopic={post.topics
                      .toString(2)
                      .split("")
                      .reverse()
                      .map((elem, idx) => {
                        if (parseInt(elem) === 1) return ++idx
                      })
                      .filter(elem => elem !== undefined)}
                    free={post.free}
                  />
                ))}
              </Slider>
            ) : (
              <div className={styles.nothingStory}>
                "등록된 오늘의 스토리가 없습니다."
              </div>
            )}
          </div>
        </div>

        <div>
          <div className={styles.detail}>
            <InputTitle
              name={"오늘의 그림단어"}
              titleSize={22}
              style={{
                display: "flex",
                margin: 0,
                marginBottom: 30,
                marginRight: "auto",
              }}
            />

            <div
              style={{
                display: "flex",
                fontSize: "16px",
                placeItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => navigate("/visible/card/list")}
            >
              <div>전체 보기</div>
              <img
                src={fullView}
                style={{ width: "17px", height: "17px", marginLeft: "3px" }}
              />
            </div>
          </div>

          <div className={styles.slideCon}>
            {todayCard.length > 0 ? (
              <Slider name={"cardSlideBanner"}>
                {todayCard?.map(post => (
                  <div className={styles.imgCard}>
                    <img
                      src={getThumb(post.thumb_title_uuid)}
                      onClick={() => {
                        permission(
                          `/visible/card/detail?illust_no=${post.illust_no}&currentPage=1&pagePostSize=7`
                        )
                      }}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <div className={styles.nothingStory}>
                "등록된 오늘의 단어가 없습니다."
              </div>
            )}
          </div>
        </div>

        {memberType === rank.REGULAR ? (
          <div>
            <div className={styles.detail}>
              <InputTitle
                name={`${profile.name}님이 찜한 스토리`}
                titleSize={22}
                style={{
                  display: "flex",
                  margin: 0,
                  marginRight: "auto",
                }}
              />

              <div
                style={{
                  display: "flex",
                  fontSize: "16px",
                  placeItems: "center",
                  minWidth: "fit-content",
                }}
                onClick={() =>
                  navigate(`/myBookshelf/?tileName=${MINE.STORY.like}`)
                }
              >
                <div>전체 보기</div>
                <img
                  src={fullView}
                  style={{ width: "17px", height: "17px", marginLeft: "3px" }}
                />
              </div>
            </div>

            {likeStory.length > 0 ? (
              <div className={styles.slideCon} style={getSlideWidth(likeStory)}>
                <Slider name={"frontSlide"} length={likeStory?.length}>
                  {likeStory?.map(post => {
                    return (
                      <Story
                        registerTime={post.publishTime}
                        key={post.story_no}
                        story_no={post.story_no}
                        title={post.title}
                        introText={post.introText}
                        thumb_title_uuid={post.thumb_title_uuid}
                        cateMain={post.cateMain}
                        cateSub={post.cateSub}
                        Like={post.Like}
                        free={post.free}
                      />
                    )
                  })}
                </Slider>
              </div>
            ) : (
              <div className={styles.nothingStory}>
                "찜한 스토리가 없습니다. 읽고 싶은 스토리를 찜해보세요."
              </div>
            )}
          </div>
        ) : null}

        <div>
          <div className={styles.detail}>
            <InputTitle
              name={
                memberType === rank.REGULAR
                  ? `${profile.name}님을 위한 추천 스토리`
                  : "당신을 위한 스토리"
              }
              titleSize={22}
              style={{
                display: "flex",
                margin: 0,
                marginRight: "auto",
              }}
            />
            {memberType === rank.REGULAR ? (
              <div />
            ) : (
              <div
                style={{
                  display: "flex",
                  fontSize: "16px",
                  placeItems: "center",
                  minWidth: "fit-content",
                }}
                onClick={() => navigate("/story/yourStory/list")}
              >
                <div>전체 보기</div>
                <img
                  src={fullView}
                  style={{ width: "17px", height: "17px", marginLeft: "3px" }}
                />
              </div>
            )}
          </div>

          {forUserStory.length > 0 ? (
            <div
              className={styles.slideCon}
              style={getSlideWidth(forUserStory)}
            >
              <Slider name={"frontSlide"} length={forUserStory?.length}>
                {forUserStory?.map(post => (
                  <Story
                    registerTime={post.publishTime}
                    key={post.story_no}
                    story_no={post.story_no}
                    title={post.title}
                    introText={post.introText}
                    thumb_title_uuid={post.thumb_title_uuid}
                    cateMain={post.cateMain}
                    cateSub={post.cateSub}
                    Like={post.Like}
                    free={post.free}
                  />
                ))}
              </Slider>
            </div>
          ) : (
            <div className={styles.nothingStory}>
              "등록된 스토리가 없습니다."
            </div>
          )}
        </div>

        {/* "에디터팀 큐레이션" */}
        <div>
          <div className={styles.detail}>
            <InputTitle
              name={ediCuration?.title}
              titleSize={22}
              style={{
                display: "flex",
                margin: 0,
                marginRight: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "800px",
              }}
            />

            <div
              style={{
                display: "flex",
                fontSize: "16px",
                placeItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() =>
                navigate(
                  `/news/detail?exhibit_no=${ediCuration?.exhibit_no}&currentPage=1&pagePostSize=5`
                )
              }
            >
              <div>자세히 보기</div>
              <img
                src={fullView}
                style={{ width: "17px", height: "17px", marginLeft: "3px" }}
              />
            </div>
          </div>

          {ediCuration.NeighborStory?.length > 0 ? (
            <div
              className={styles.slideCon}
              style={getSlideWidth(ediCuration.NeighborStory)}
            >
              <Slider
                name={"frontSlide"}
                length={ediCuration.NeighborStory.length}
              >
                {ediCuration.NeighborStory.map(post => (
                  <Story
                    registerTime={post.publishTime}
                    key={post.story_no}
                    story_no={post.story_no}
                    title={post.title}
                    introText={post.introText}
                    thumb_title_uuid={post.thumb_title_uuid}
                    cateMain={post.cateMain}
                    cateSub={post.cateSub}
                    Like={post.Like}
                    free={post.free}
                  />
                ))}
              </Slider>
            </div>
          ) : (
            <div className={styles.nothingStory}>
              "등록된 큐레이션이 없습니다."
            </div>
          )}
        </div>

        {/* "교육팀 큐레이션" */}
        <div>
          <div className={styles.detail}>
            <InputTitle
              name={eduCuration?.title}
              titleSize={22}
              style={{
                display: "flex",
                margin: 0,
                marginRight: "auto",
              }}
            />

            <div
              style={{
                display: "flex",
                fontSize: "16px",
                placeItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() =>
                navigate(
                  `/news/detail?exhibit_no=${eduCuration?.exhibit_no}&currentPage=1&pagePostSize=5`
                )
              }
            >
              <div>자세히 보기</div>
              <img
                src={fullView}
                style={{ width: "17px", height: "17px", marginLeft: "3px" }}
              />
            </div>
          </div>

          {eduCuration?.NeighborStory?.length > 0 ? (
            <div
              className={styles.slideCon}
              style={getSlideWidth(eduCuration?.NeighborStory)}
            >
              <Slider
                name={"frontSlide"}
                length={eduCuration.NeighborStory.length}
              >
                {eduCuration.NeighborStory.map(post => (
                  <Story
                    registerTime={post.publishTime}
                    key={post.story_no}
                    story_no={post.story_no}
                    title={post.title}
                    introText={post.introText}
                    thumb_title_uuid={post.thumb_title_uuid}
                    cateMain={post.cateMain}
                    cateSub={post.cateSub}
                    Like={post.Like}
                    free={post.free}
                  />
                ))}
              </Slider>
            </div>
          ) : (
            <div className={styles.nothingStory}>
              "등록된 큐레이션이 없습니다."
            </div>
          )}
        </div>

        <DisplayB
          style={{
            width: "1050px",
            height: "142px",
            margin: "30px auto 30px auto",
            cursor: "pointer",
          }}
          type={"display"}
        />
      </div>
      <div
        className={styles.video}
        style={{
          width: "100vw",
        }}
      >
        <div className={styles.detail}>
          <InputTitle
            name={"영상 스토리"}
            titleSize={22}
            style={{
              display: "flex",
              margin: "0px auto 33px 0px",
            }}
          />
          <div></div>
        </div>

        <div className={styles.videoCon}>
          <div className={styles.left}></div>
          <div className={styles.right}></div>

          {Video?.length > 0 ? (
            <Slider name={"frontVideo"} length={Video.length}>
              {Video.map((post, i) => (
                <div
                  key={post.video_no}
                  onClick={() => {
                    navigate(
                      `/visible/media/detail?video_no=${
                        post.video_no
                      }&currentPage=1&pagePostSize=7&idx=${i + 1}`
                    )
                  }}
                  className={styles.imgVideo}
                >
                  <img src={getThumb(post.thumb_title_uuid)} />
                  <div className={styles.text}>
                    <img
                      src={peachIcons}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginRight: "19px",
                      }}
                    />
                    <div>
                      <div>{post.title}</div>
                      <div>{post.introText}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className={styles.nothingStory}>"등록된 영상이 없습니다."</div>
          )}
        </div>
      </div>
      <div className={styles.con}>
        <div>
          <div className={styles.detail}>
            <InputTitle
              name={"오늘은 이런 수업 어때요?"}
              titleSize={22}
              style={{
                display: "flex",
                margin: 0,
                marginRight: "auto",
              }}
            />

            <div
              style={{
                display: "flex",
                fontSize: "16px",
                placeItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => navigate("/class/lesson/list")}
            >
              <div>전체 보기</div>
              <img
                src={fullView}
                style={{ width: "17px", height: "17px", marginLeft: "3px" }}
              />
            </div>
          </div>

          {Suggest?.length > 0 ? (
            <div
              className={styles.slideCon}
              name="suggest"
              style={getSlideWidth(Suggest)}
            >
              <Slider name={"frontSlide"} length={Suggest?.length}>
                {Suggest?.map((post, i) => (
                  <Lesson post={post} key={i} />
                ))}
              </Slider>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default Front

import { setSort } from "utils"
import bannerClose from "images/closeBtn.png"
import modalClose from "images/modalClose.png"
import cookie from "react-cookies"
import dayjs from "dayjs"
import api from "apis"

const usePopup = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [popList, setPopList] = useState([])
  const [index, setIndex] = useState(0)
  const access_token = getJson("access_token")?.token ?? Symbol()
  // const keyword = "excepts"
  // const cookieData = cookie.load(keyword, { path: "/front" })
  // const exceptList = cookieData ? JSON.parse(cookieData) : []
  const onClose = data_no => {
    setIndex(index => index + 1)
    if (data_no) {
      // 다시보지않기
      cookie.save(`popup_${data_no}`, data_no, {
        path: "/",
        expires: dayjs().add(1, "day").toDate(),
      })
    }
  }

  useEffect(() => {
    // 팝업리스트 가져오기
    ;(async () => {
      const result = await api.getPopup()
      console.log(result)
      if (result && result.length > 0) {
        setPopList(() =>
          setSort(result, "popup_no").filter(v => {
            const popupCokie = cookie.load(`popup_${v.popup_no}`, {
              path: "/",
            })
            return !popupCokie
          })
        )
      }
    })()
  }, [])

  useEffect(() => {
    if (popList[index]) {
      setmodalOpen(true)
      setModalMain(
        <div className={styles.popCon}>
          <div>
            <div name={"closeOnce"} onClick={() => onClose()}>
              <img src={modalClose} alt="닫기" />
            </div>
          </div>
          <div>
            <img
              src={getThumb(popList[index].thumb_logo_uuid)}
              alt="test"
              name="main"
              onClick={() => {
                globalThis.location.href = `${popList[index].link}`
              }}
            />
          </div>
          <div>
            <div
              name={"closeToday"}
              onClick={() => onClose(popList[index].popup_no)}
              style={{ cursor: "pointer" }}
            >
              <p>오늘 하루 다시 보지 않기</p>
              <img
                src={bannerClose}
                alt="닫기"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
        </div>
      )
    } else {
      setmodalOpen(false)
    }
  }, [popList, index])
}
